<template>
    <section class="cotizaciones-enviadas container-fluid overflow-auto custom-scroll my-2">
        <titulo-divisor titulo="Cotizaciones creadas">
            <div class="row">
                <div class="col-auto">
                    <el-input v-model="buscarListado" placeholder="Buscar cotizaciones" size="small" />
                </div>
                <div v-if="cantFiltros == 0" class="col-auto my-auto pl-1 icon-option">
                    <el-tooltip content="Filtro" placement="bottom" effect="light" visible-arrow>
                        <button class="btn btn-square32-5d ml-2 cr-pointer" @click="abrirModalFiltros">
                            <i class="icon-filter text-white" />
                        </button>
                    </el-tooltip>
                </div>
                <div v-if="cantFiltros > 0" class="col-auto filtro-contador my-auto pl-1">
                    <div class="d-flex border-general br-4">
                        <button class="btn btn-square32-5d cr-pointer" @click="abrirModalFiltros">
                            <i class="icon-filter text-white" />
                        </button>
                        <div class="d-middle px-2">
                            <p class="f-12">3</p>
                            <i class="icon-close-circle f-11 text-muted pl-1 cr-pointer" @click="limpiarFiltro" />
                        </div>
                    </div>
                </div>
            </div>
        </titulo-divisor>
        <div class="row mt-4">
            <div class="col-3 my-auto text-general f-14"> </div>
            <div class="col-5 my-auto text-general f-14 d-flex">
                <p class="f-300 mx-3"> <span class="f-600">PU:</span>  Parcentaje de utilidad</p>
                <p class="f-300 mx-3"> <span class="f-600">VU:</span>  Valor de utilidad</p>
            </div>
            <div class="col-4 text-right">
                <button class="btn f-12 btn-general"
                    :class="permitAction('cotizaciones.clientes.crear') ? 'cr-pointer' : 'cr-not-allowed'" 
                    @click="functionPermitAction('cotizaciones.clientes.crear', crearCotizacion)"
                >Crear cotización</button>
            </div>
        </div>
        <tabla-general :mostrarBuscador="false" alturaTabla="550px" :usarServidor="true" :usarPaginacion="true" :servidorData="cotizaciones" @paginar="listarCotizaciones">
            <el-table-column label="Referencia" prop="referencia">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 f-600 tres-puntos cr-pointer" @click="verCotizacion(scope.row.id)">
                        {{ scope.row.referencia || '-' }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Nombre" prop="nombre">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.nombre }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="País" prop="pais" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.configuracion_pais_utilidad.pais.pais }}
                    </p>
                    <div class="text-5d f-12 bg-white br-5 mx-auto" style="border: 1px solid #dbdbdb; max-width: 80px;">
                        {{ scope.row.porcentaje_utilidad_pais  }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="Operario" prop="operario">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.user.nombre }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Fecha" prop="fechaSolicitud" width="85">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">
                        {{ scope.row.created_at }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Etapas" prop="etapas" align="center" width="75">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.cantidad_etapas }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Configuraciones" prop="configuraciones" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.cantidad_configuraciones }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Configuraciones VU" prop="confVU" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ formatoMoneda(scope.row.confVU) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Valor" prop="valor" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ formatoMoneda(scope.row.valor) }}
                    </p>
                </template>
            </el-table-column>
        </tabla-general>
         <!-- partials  -->
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-12 my-3">
                    <p class="input-label-top">Nombre proyecto</p>
                    <el-select v-model="valueSelect" placeholder="Seleccionar proyecto" filterable size="small" class="w-100">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select>
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top">Número de referencia</p>
                    <el-select v-model="valueSelect" placeholder="Seleccionar referencia" filterable size="small" class="w-100">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select>
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top"> Operario </p>
                    <el-select v-model="valueSelect" placeholder="Seleccionar operario" filterable size="small" class="w-100">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select>
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top"> País </p>
                    <el-select v-model="valueSelectPais" placeholder="Seleccionar país" filterable size="small" class="w-100">
                        <el-option v-for="item in select_countries" :key="item.id" :label="item.pais" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top"> Rango de fecha </p>
                    <el-slider v-model="rangeValorCotizado" range :min="0" :max="100"  />
                    <!--<el-date-picker v-model="rangoFecha" type="date" placeholder="Seleccionar fecha" size="small" class="w-100" /> -->
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="limpiarFiltro"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filtrarMateriales"> Filtrar </button>
            </div>
        </modal-lateral>
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    data(){
        return{
            buscarListado:'',
            options: [
                {
                    value: 'Option1',
                    label: 'Option1'
                }, 
                {
                    value: 'Option2',
                    label: 'Option2'
                }, 
                {
                    value: 'Option3',
                    label: 'Option3'
                }, 
                {
                    value: 'Option4',
                    label: 'Option4'
                }, 
                {
                    value: 'Option5',
                    label: 'Option5'
                }
            ],
            select_countries:[
                
            ],
            cantFiltros: 0,
            valueSelect: '',
            valueSelectPais: '',
            estadoFilter: '',
            checkEstados: [],
            listadoCotizacionesVigentes:[
                {
                    id: 1,
                    referencia: '0001',
                    nombre: 'CSA G40-21',
                    pais: 'Mexico',
                    operario: 'Luis Ordoñes',
                    fechaSolicitud: '26/08/21',
                    etapas: 5,
                    configuraciones: 213,
                    confVU: '800.000',
                    valor: '800.000',
                },],
            pickerFecha: '',
            rangeValorCotizado: [5,80],
        }
    },
    computed: {
        ...mapGetters({
            cotizaciones: 'cotizacion/cotizacionClientes/cotizaciones',
        }),
    },
    async created(){
        await this.listarCotizaciones();
    },
    methods:{
        ...mapActions({
            Action_get_cotizaciones: 'cotizacion/cotizacionClientes/Action_get_cotizaciones',
        }),
        async listarCotizaciones(page = 1){
            await this.Action_get_cotizaciones({page});
        },
        abrirModalFiltros(){    
            this.$refs.abrirModalFiltros.toggle()
        },
        filtrarMateriales(){
            this.$refs.abrirModalFiltros.toggle()
            this.cantFiltros = 1
        },
        limpiarFiltro(){
            this.$refs.abrirModalFiltros.toggle()
            this.cantFiltros = 0
        },
        crearCotizacion(){
            this.$router.push({ name: 'cotizaciones.crear', })
        },
        verCotizacion(id_cotizacion){
            this.$router.push({
                name: 'cotizaciones.ver.detalle',
                params: {
                    id_cotizacion: id_cotizacion
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.cotizaciones-enviadas{
    height: calc(100vh - 245px);
}
</style>